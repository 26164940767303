const bootstrap = require('@/bootstrap/bootstrap.js');

/**
 * This file is the future entrypoint into the application when built with Webpack.
 * Everything will happen here, such as importing angular, configuring angular, and starting angular. (Although not
 * directly in this file, it will require() things like 1-bootstrap.js and 2-create.js
 */

console.log('Welcome to ProPoint EPOS.');
bootstrap();

